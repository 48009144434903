import * as React from 'react';
import { EntityForm, EntityValidationError, IEntityFormState } from "@inwink/entityform";
import type { Entities } from '@inwink/entities/entities';
import type  { IFileInputManager } from '@inwink/fileutils/fileapi';
import type { ISurveyAnswerCommunityProps } from './surveyanswer.props';

interface ISurveyAnswerCommunityActionBarProps extends ISurveyAnswerCommunityProps {
    entityTemplate: Entities.IEntityTemplate;
    entityFormTemplate: Entities.IExtendedFieldsFormTemplate;
    currentGroup: number;
    data: Entities.ISurveyAnswerCommunity;
    validations?: EntityValidationError[];
}

interface ISurveyAnswerCommunityEntityForm extends ISurveyAnswerCommunityActionBarProps {
    defaultValues: any;
    onChange: (data: Entities.ISurveyAnswerCommunity, state: IEntityFormState, patch, computed) => void;
    onValidationChange: (state: IEntityFormState) => void;
    showValidations: boolean;
    fileInputManager: IFileInputManager;
    scrollErrorInView: Date;
}

export function SurveyAnswerCommunityEntityForm(props: ISurveyAnswerCommunityEntityForm) {
    return <div className="surveygroup bloc-defaultbg modalcontentpadding">
        <EntityForm
            entityFormId="surveycommunity"
            fileinputmanager={props.fileInputManager}
            displayLanguage={props.i18n.currentLanguageCode}
            languages={props.community?.detail?.configuration?.global?.supportedLanguages}
            showGroup={props.currentGroup}
            entityTemplate={props.entityTemplate}
            entityFormTemplate={props.entityFormTemplate.groups}
            computedProperties={props.entityFormTemplate.computedProperties}
            applyComputedProperties={props.entityFormTemplate.applyComputedProperties}
            entityValue={props.data}
            onValueChanged={props.onChange}
            onValidationChanged={props.onValidationChange}
            showValidations={props.showValidations}
            entityName="SurveyAnswerCommunity"
            datatimestamp={props.datastamp}
            defaultValues={props.defaultValues}
            scrollFirstErrorInView={props.scrollErrorInView}
        />
    </div>;
}