import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import { registerBloc } from '../../../components/dynamicpage/blocscatalog';
import { MemberContactTemplateActions } from './networking/actions/membercontacttemplateactions';
import { BlocTeaserMembers, blocTeaserMembersData } from './networking/blocs/teasermembers';

registerBloc("commu-teasermembers", {
    component: BlocTeaserMembers,
    datainit: blocTeaserMembersData
});

registerItemTemplatePart("membercontactactions", MemberContactTemplateActions);

