import * as React from 'react';
import { connect } from 'react-redux';
import { withI18nHelper } from '@inwink/i18n';
import { wrapReduxStore, IInwinkStore } from '@@store/index';
import { withRouter } from 'react-router-dom';
import { MemberContactActionsBaseComponent } from './membercontactactionsbase';

import type { 
    IRequestItem, 
    IMember,
    IMemberContact, 
    IMemberContactRequest 
} from '@@community/data';
import type { ItemTemplateProps } from '@@components/templates/itemtemplate.props';
import type { States } from '@@services/services';

import "./membercontacttemplateactions.less";

export interface IMemberContactTemplateActionsComponentProps extends ItemTemplateProps {
    community?: States.ICommunityState;
    store?: IInwinkStore;
    user?: States.IAppCurrentUser;
    onStatusChanged?: () => void;
}

interface IMemberContactTemplateActionsComponentState {
    canNetwork: boolean;
    isContact: boolean;
    member?: IMember;
    contact?: IMemberContact;
    contactRequest?: IMemberContactRequest;
    btnText?: string;
    btnClass?: string;
    contactRequestReceived?: IRequestItem;
}

@withI18nHelper()
class MemberContactTemplateActionsComponent
    extends React.Component<IMemberContactTemplateActionsComponentProps, IMemberContactTemplateActionsComponentState> {
    render() {
        return <MemberContactActionsBaseComponent
            community={this.props.community}
            i18nHelper={this.props.i18nHelper}
            currentMember={this.props.user?.member}
            targetMemberId={this.props.data?.id}
            onStatusChanged={this.props.onStatusChanged}
        />;
    }
}

function mapStateToProps(state: States.IAppState) {
    return {
        community: state.community,
    };
}

function mapDispatchToProps() {
    return {
    };
}

export const MemberContactTemplateActions: React.Component<IMemberContactTemplateActionsComponentProps> = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(wrapReduxStore(MemberContactTemplateActionsComponent) as any) as any) as any;