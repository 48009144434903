import * as React from 'react';
import {
    OrderRegistration,
    getDefaultOrderRegistrationStepCatalog
} from '@inwink/ticketing/orderregistration';
import {
    defaultBasket,
} from '@inwink/ticketing/ordermanager';
import type {
    IOrderRegistrationBasket,
    IOrderRegistrationBasketItem,
    IOrderRegistrationItem,
    IOrderRegistrationStep,
    PaymentMode,
    IOrderRegistrationPaymentProcessConfiguration,
    IOrderData,
    IBlocOrderRegistrationOptionsBase
} from '@inwink/ticketing/definitions';
import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import { productsQuery } from '@@modules/community/api/products';
import { IOrderRegistrationOrderDataStepProps, OrderRegistrationOrderDataStep, orderRegistrationOrderDataStepFormatBasketToCheck }
    from './steps/orderregistration.step.orderdata';
import { type IOrderRegistrationItemsDataStepProps, OrderRegistrationItemsDataStep }
    from './steps/orderregistration.step.itemsdata';
import {
    cancelOrder,
    checkDiscountCode,
    checkOrder,
    createOrder,
    getOrderBag,
    proceedOnlinePayment,
    updateOrder,
    validateOrder
} from '@@modules/community/api/order';
import { buyerOrderDatasourceV3 } from '@@modules/community/api/buyer';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import type { States } from '@@services/services';
import { CommunityTradsContext } from '@@modules/community/shell/communitytradscontext';
import { withAppTrads } from '@@components/apptrads';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import type { IMembershipLevel } from '@@modules/community/data';
import { membershipQuery } from '@@modules/community/api/membershiplevel';
import { getPredicate } from '@inwink/expressions';
import type { IOrderRegistrationProductsStepProps } from '@inwink/ticketing/steps/orderregistration.step.products';
import type { IDynamicBlocWithProps } from '@@components/dynamicpage/definitions';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { DynamicBloc } from '@@components/dynamicpage/common';
import { BlocContent, BlocTitle } from '@@components/dynamicpage/common.title';
import { Entities } from '@inwink/entities/entities';
import { NavLink } from 'react-router-dom';
import { setDatasInLocalStorage } from '@inwink/ticketing/components/utils';
import { ProductItems } from '@@modules/order/steps/products/product.items';
import { orderRegistrationItemsDataStepFormatBasketToCheck } 
    from '@inwink/ticketing/components/itemsdata/bloc.orderregistration.itemsdata';
import { Loader } from '@inwink/loader';
import { getInitialBasketForRenewMembership } from './renewmembership.helper';

import './bloc.orderregistration.less';

const orderTrads = require('@inwink/tradscompanion/order.json');
const communityOrderTrads = require('@inwink/tradscompanion/order.community.json');

interface ICommunityBlocOrderRegistrationState {
    steps?: IOrderRegistrationStep<any>[];
    paymentModes: PaymentMode[];
    initialBasket?: IOrderRegistrationBasket;
    membershipLevels?: IMembershipLevel[];
    orderid?: string;
    buyerEmail?: string;
    isLoading?: boolean;
}

interface ICommunityBlocOrderRegistrationProps
    extends IDynamicBlocWithProps<ICommunityBlocOrderRegistrationState, ICommunityBlocOrderRegistrationTemplateProperties> {
    i18nHelper: Entities.i18nHelper;
    productId?: string;
    quantity?: number;
}

interface ICommunityBlocOrderRegistrationTemplateProperties extends IBlocOrderRegistrationOptions {
    steps: IBlocOrderRegistrationBlocPropertiesStep[];
    paymentProcessComponentConfiguration: IOrderRegistrationPaymentProcessConfiguration;

}

interface IBlocOrderRegistrationOptions extends IBlocOrderRegistrationOptionsBase {
}

interface IBlocOrderRegistrationBlocPropertiesStep {
    id?: string;
    type: string;
    properties?: { stepLabels?: VisualTheme.IAppLabel, [name: string]: any };
}

const mapStateToProps = (state: States.IAppState) => {
    return {
        productId: state.orderBasket.productId,
        quantity: state.orderBasket.quantity,
        user: state.user
    };
};

@withAppTrads(orderTrads, communityOrderTrads)
@connectwith(mapStateToProps)
export class CommunityBlocOrderRegistration
    extends React.Component<ICommunityBlocOrderRegistrationProps, ICommunityBlocOrderRegistrationState> {
    membershipLevelsPromise: Promise<any>;

    constructor(props: ICommunityBlocOrderRegistrationProps) {
        super(props);
        const paymentModes: PaymentMode[] = [];
        const pmConfig = this.props.community?.detail?.configuration?.ticketing?.paymentModes;
        if (pmConfig) {
            if (pmConfig.onBilling) {
                paymentModes.push('Onbilling');
            }
            if (pmConfig.online) {
                paymentModes.push('Online');
            }
        }

        let initialBasket: IOrderRegistrationBasket;

        if (this.props.productId) {
            initialBasket = defaultBasket(this.props.location, this.props.template.properties?.enableMonoProduct ? true : false);
            initialBasket.items.push({
                productId: this.props.productId,
                quantity: this.props.quantity ?? 1,
                infos: []
            });
        }

        this.state = {
            steps: this.getCommunitySteps(),
            paymentModes: paymentModes,
            initialBasket: initialBasket,
            orderid: null,
            isLoading: false
        };
    }

    componentDidMount(): void {
        if (window.inwinkRenewOrderId) {
            const inwinkRenewOrderId = window.inwinkRenewOrderId;
            delete window.inwinkRenewOrderId;

            this.handleRenewMembership(inwinkRenewOrderId);
        }
    }

    handleRenewMembership = (inwinkRenewOrderId: string) => {
        this.setState({ isLoading: true });

        buyerOrderDatasourceV3(this.props.community.requestManagers.apiFront)
            .then((ds) => ds.query({
                selects: {
                    id: true,
                    orderItems: {
                        productId: true,
                        services: {
                            applicationData: true
                        }
                    },
                    buyer: {
                        $all: true
                    }
                },
                filters: {
                    id: inwinkRenewOrderId
                }
            }))
            .then((resOrder) => {
                const order = resOrder?.data?.[0];
                if (!order) {
                    return;
                }

                const initialBasket = getInitialBasketForRenewMembership(inwinkRenewOrderId, order);
                if (!initialBasket) {
                    return;
                }

                this.setState({ initialBasket: initialBasket,
                    steps: this.getCommunitySteps(["orderdata", "summary"], (step) => {
                        if (step?.stepType === "orderdata") {
                            if (step.stepProps?.inlineBuyerTemplate) {
                                step.stepProps.inlineBuyerTemplate = this.updateFieldToReadOnly(step.stepProps
                                    .inlineBuyerTemplate, "email");
                            } else if (step.stepProps?.buyerformtemplate) {
                                step.stepProps.buyerformtemplate = this.updateFieldToReadOnly(step.stepProps
                                    .buyerformtemplate, "email");
                            }
                        }
                    })
                });
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    getTemplate(entity: string) {
        const name = entity.toLowerCase();
        const template = this.props.community.data?.fieldtemplates?.data.filter((e) => e.entityName.toLowerCase() === name)[0];
        return template?.template;
    }

    updateFieldToReadOnly(template: Entities.IExtendedFieldsFormTemplate, targetKey: string) {
        function updateFieldsInGroup(group: Entities.IExtendedFieldsFormGroupTemplate) {
            if (group.fields) {
                group.fields.forEach(field => {
                    if (field.key === targetKey) {
                        field.isReadOnly = true;
                    }
                });
            }
        
            if (group.groups) {
                group.groups.forEach(updateFieldsInGroup);
            }
        }
      
        template.groups.forEach(updateFieldsInGroup);

        return template;
    }

    getCommunitySteps(stepsToInclude?: string[], customizeStep?: (step: IOrderRegistrationStep<any>) => void) {
        if (!this.props.user.checked) {
            return null;
        }

        const steps: IOrderRegistrationStep<any>[] = [];

        let templateSteps = this.props.template?.properties?.steps;
        if (!templateSteps?.length) {
            return steps;
        }

        if (stepsToInclude?.length) {
            templateSteps = templateSteps.filter((step) => stepsToInclude.includes(step.type));
        }

        const defaultStepCatalog = getDefaultOrderRegistrationStepCatalog();

        let orderVatExempt: boolean;

        for (let index = 0; index < templateSteps.length; index++) {
            const templateStep = templateSteps[index];
            const stepId = templateStep.id;
            const stepType = templateStep.type;
            const stepLabels = templateStep.properties?.stepLabels;
            const stepProperties = templateStep.properties;

            let step: IOrderRegistrationStep<any>;
            const stepDef = defaultStepCatalog[stepType];
            if (stepDef != null) {
                const stepProps: any = {};
                if (stepProperties && stepProperties.config) {
                    stepProps.config = stepProperties.config;
                }
                if (stepType == "products" && stepProperties) {
                    const { filters, template, selectedOption, productsList, sort,
                        excludedProductTypes } = stepProperties;

                    if (stepId) {
                        stepProps.stepId = stepId;
                    }
                    if (filters) {
                        (stepProps as IOrderRegistrationProductsStepProps).itemsFilter = getPredicate(filters);
                    }
                    if (template) {
                        stepProps.template = template;
                    }
                    if (selectedOption) {
                        stepProps.selectedOption = selectedOption;
                    }
                    if (productsList) {
                        stepProps.productsList = productsList;
                    }
                    if (excludedProductTypes) {
                        stepProps.excludedProductTypes = excludedProductTypes;
                    }
                    if (sort) {
                        stepProps.sort = sort;
                    }

                    const config = stepProps?.config || {};

                    const { properties } = this.props.template;

                    const { displayModePricing, displayVatProductsDetail } = properties;

                    config.showPriceHT = displayModePricing === "HT"
                        || displayModePricing === "TTC_BASKET_LAST_STEP_ONLY";
                    config.showPriceVAT = displayVatProductsDetail;

                    stepProps.config = { ...stepProps.config, ...config };

                    stepProps.stepData = this.props.community.data;
                }
                step = stepDef(stepId, stepLabels, stepProps);
                if (stepType === "products") {
                    step.component = ProductItems;
                    step.stepType = stepType;
                }
                if (stepType === "summary") {
                    step.stepType = stepType;

                    const config = stepProperties?.config || {};
                    config.orderVatExempt = orderVatExempt;
                }
            } else {
                switch (stepType) {
                    case "itemsdata": {
                        let itemsFilter;
                        const rsid = stepProperties?.relatedStepProductsId;
                        if (rsid) {
                            const relatedStep = templateSteps.filter(s => s.id == rsid)[0];
                            if (relatedStep && relatedStep.properties?.filters) {
                                itemsFilter = getPredicate(relatedStep.properties.filters);
                            }
                        }
                        step = {
                            id: stepId,
                            stepType: stepType,
                            stepLabel: "orderregistration.breadcrumb.itemsdata",
                            stepLabels: stepLabels,
                            component: OrderRegistrationItemsDataStep,
                            stepProps: {
                                membertemplate: this.getTemplate("Member"),
                                memberformtemplate: stepProperties?.inlineAttendeeTemplate,
                                itemsFilter: itemsFilter,
                                community: this.props.community,
                                user: this.props.user,
                            } as IOrderRegistrationItemsDataStepProps,
                            formatBasketToCheck: orderRegistrationItemsDataStepFormatBasketToCheck
                        };
                        break;
                    }
                    case "orderdata":
                        orderVatExempt = stepProperties?.config?.orderVatExempt;

                        step = {
                            id: stepId,
                            stepType: stepType,
                            stepLabel: "orderregistration.breadcrumb.orderdata",
                            stepLabels: stepLabels,
                            component: OrderRegistrationOrderDataStep,
                            stepProps: {
                                buyertemplate: this.getTemplate("Buyer"),
                                buyerformtemplate: stepProperties?.inlineBuyerTemplate ?? stepProperties?.buyerformtemplate,
                            } as IOrderRegistrationOrderDataStepProps,
                            formatBasketToCheck: orderRegistrationOrderDataStepFormatBasketToCheck
                        };
                        break;
                    default:
                        break;
                }
            }

            if (step) {
                customizeStep?.(step);
                steps.push(step);
            }
        }
        
        if (steps.length > 0) {
            steps[0].disablePrevious = true;

            if (steps.length > 1) {
                for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
                    const step = steps[stepIndex];
                    step.nextButtonOptions = {
                        label: stepIndex == steps.length - 1 ? undefined : "actions.next"
                    };
                }
            }
        }

        return steps;
    }

    getDataSource() {
        return {
            getItems: (itemsIds: string[]) => {
                const opt = {
                    selects: {
                        $all: true,
                        services: {
                            $all: true
                        }
                    },
                    filters: itemsIds?.length > 0 ? {
                        $or: [
                            {
                                id: { $in: itemsIds }
                            }
                        ]
                    } : null
                } as IInwinkEntityV3QueryOptions;
                return productsQuery(
                    this.props.community.requestManagers.apiFront,
                    opt
                ).then((res) => {
                    return res.data.map((p) => {
                        const applicationRule = p.services && p.services[0] && p.services[0].applicationRule;
                        const itemPriceTtc = (p as any).priceTTC ?? 0;
                        const itemPrice = p.price ?? 0;
                        const item: IOrderRegistrationItem = {
                            id: p.id,
                            name: p.name,
                            category: p.category,
                            description: p.description,
                            hasValidationSAS: p.hasValidationSAS,
                            basePriceHt: itemPrice,
                            basePriceTtc: itemPriceTtc,
                            vat: p.vat,
                            vatAmount: (itemPriceTtc - itemPrice),
                            applicationRule: {
                                membershipLevelId: applicationRule.membershipLevelId,
                                interval: applicationRule.interval,
                                intervalCount: applicationRule.intervalCount
                            },
                            bundleItems: null,
                            initialProduct: p
                        };

                        return item;
                    });
                });
            },
            getOrderBag: (orderId: string, dataInfo: string, sign: string, contentTemplateId: string, blocId: string) => {
                return getOrderBag(this.props.community.requestManagers, orderId, dataInfo, sign, contentTemplateId, blocId);
            },
            check: (basket) => {
                const paymentMode = null;
                const order = basket.order ?? {};
                const buyerInfos = basket.buyer ?? {};
                const baseReturnUrl = "";
                const items = basket.items?.map((item) => {
                    return {
                        productId: item.productId,
                        quantity: item.quantity,
                        infos: item.infos ?? []
                    };
                }) ?? [];
                const discountCodes = basket.discountCodes ?? [];
                return checkOrder(this.props.community.requestManagers, paymentMode, order,
                    discountCodes, buyerInfos, items, baseReturnUrl,
                    this.props.page.template.id,
                    this.props.bloctemplate.id)
                    .then((res) => {
                        return res;
                    });
            },
            checkDiscountCode: (discountCode: string) => {
                return checkDiscountCode(this.props.community.requestManagers, discountCode);
            },
            createOrder: (basket, isOrderFinalized) => {
                return createOrder(
                    this.props.community.requestManagers,
                    basket.paymentMode,
                    basket.order,
                    basket.discountCodes,
                    basket.buyer,
                    basket.items,
                    window.location.href,
                    this.props.community.detail.name,
                    this.props.page.template.id,
                    this.props.bloctemplate.id,
                    isOrderFinalized
                ).then((res: IOrderData) => {
                    const dataToStore = {
                        email: basket.buyer?.email,
                        price: (res as any).totalTtc // TODO: Add the property in ticketing module
                    };
                    if (res.orderId && dataToStore) {
                        this.setState({ orderid: res.orderId, buyerEmail: dataToStore.email });
                        setDatasInLocalStorage(res.orderId, dataToStore);
                    }
                    return res;
                });
            },
            updateOrder: (orderId, basket, isOrderFinalized) => {
                return updateOrder(
                    this.props.community.requestManagers,
                    basket.paymentMode,
                    { id: orderId, ...basket.order },
                    basket.discountCodes,
                    basket.buyer,
                    basket.items,
                    window.location.href,
                    this.props.community.detail.name,
                    this.props.page.template.id,
                    this.props.bloctemplate.id,
                    isOrderFinalized
                ).then((res: IOrderData) => {
                    const dataToStore = {
                        email: basket.buyer?.email || this.state.buyerEmail,
                        price: (res as any).totalTtc // TODO: Add the property in ticketing module
                    };
                    if (res.orderId && dataToStore) {
                        this.setState({ orderid: res.orderId, buyerEmail: dataToStore.email });
                        setDatasInLocalStorage(res.orderId, dataToStore);
                    }
                    return res;
                });
            },
            validateOrder: (action: string, orderId: string, orderArguments: any) => {
                return validateOrder(this.props.community.requestManagers, action, orderId, orderArguments);
            },
            cancelOrder: (orderId: string, dataInfo: string, sign: string) => {
                return cancelOrder(this.props.community.requestManagers, orderId, dataInfo, sign);
            },
            orderRedirectProceedPayment: (orderId: string) => {
                return proceedOnlinePayment(
                    this.props.community.requestManagers,
                    orderId,
                    this.props.community.detail.name, // shardTitle
                    window.location.href // returnurl
                );
            },
            getConfiguration: () => {
                return this.props.community.detail.configuration;
            }
        } as any;
    }

    loadMembershipLevels = () => {
        if (this.state.membershipLevels == null && this.membershipLevelsPromise == null) {
            this.membershipLevelsPromise = membershipQuery(this.props.community.requestManagers.apiFront).then((res) => {
                this.setState({
                    membershipLevels: res.data
                });
            });
        }
    };

    componentDidUpdate(prevProps: Readonly<ICommunityBlocOrderRegistrationProps>): void {
        const stepsAreNull = this.state.steps === null;
        const userIsChecked = !prevProps.user.checked && this.props.user.checked;

        if (stepsAreNull && userIsChecked) {
            this.setState({ steps: this.getCommunitySteps() });
        }

        if (this.props.template?.properties?.steps !== prevProps.template?.properties?.steps) {
            this.setState({ steps: this.getCommunitySteps() });
        }
    }

    render() {
        let content;
        if (this.state.steps === null || this.state.isLoading) {
            content = <Loader />;
        } else {
            const { properties } = this.props.template;

            const {
                enableMonoProduct,
                hideBreadcrumb,
                hideGlobalBasket,
                displayModePricing,
                displayVatDetailInBasket,
                displayDiscountCodeField,
                initialEmptyBasketAllowed,
                paymentProcessComponentConfiguration
            } = properties;

            content = <OrderRegistration
                {...this.props}
                className='communityorderregistrationbloc'
                steps={this.state.steps}
                datasource={this.getDataSource()}
                orderRegistrationConfig={{
                    fixedOrderData: { ...global.inwink.utm, ...global.inwink.invitation },
                    currency: this.props.community?.detail?.configuration?.ticketing?.currency,
                    disableIncompleteCartSave: this.props.community?.detail?.configuration?.ticketing?.disableIncompleteCartSave,
                    paymentModes: this.state.paymentModes,
                    buyerHasBillingInfo: true,
                    enableMonoProduct: enableMonoProduct,
                    hideBreadcrumb: hideBreadcrumb,
                    hideGlobalBasket: hideGlobalBasket,
                    basketComponentConfiguration: {
                        renderItemDetail: (basketItem, item) => {
                            return ItemDetail({
                                basketItem, item, getMembershipLevel: (id) => {
                                    if (this.state.membershipLevels == null) {
                                        this.loadMembershipLevels();
                                    }
                                    return {
                                        loading: this.state.membershipLevels == null,
                                        membershipLevel: this.state.membershipLevels?.filter(l => l.id == id)[0]
                                    };
                                }
                            });
                        },
                        showProductDetail: false,
                        displayQty: false,
                        displayDiscountCodeField: displayDiscountCodeField,
                        displayVatDetailInBasket: displayVatDetailInBasket,
                        displayModePricing: displayModePricing
                    },
                    emptyBasketConfiguration: {
                        initialEmptyBasketAllowed: initialEmptyBasketAllowed,
                        goBackLink: <NavLink className="return-btn" role="button"
                            to={this.props.urlservice.pageUrl(`products`)}>
                            <AppTextLabel i18n={"actions.return.products"} />
                        </NavLink>
                    },
                    paymentProcessComponentConfiguration:
                    paymentProcessComponentConfiguration,
                }}
                basket={this.state.initialBasket}
            />;
        }

        return <CommunityTradsContext>
            <DynamicBloc
                {...this.props}
                contentCSSClass="bloc-defaultbg"
                className={'orderregistrationbloc'}
            >
                <BlocTitle {...this.props} className="bloc-header" />
                <BlocContent {...this.props}>
                    {content}
                </BlocContent>
            </DynamicBloc>
        </CommunityTradsContext>;
    }
}

interface IOrderRegistrationItemApplicationRule {
    membershipLevelId: string;
    interval: string;
    intervalCount: number;
}

const ItemDetail = (props: {
    basketItem: IOrderRegistrationBasketItem, item: IOrderRegistrationItem,
    getMembershipLevel: (id: string) => { loading: boolean, membershipLevel?: IMembershipLevel }
}) => {
    const applicationRule: IOrderRegistrationItemApplicationRule = props.item?.applicationRule;
    if (applicationRule?.membershipLevelId) {
        const res = props.getMembershipLevel(applicationRule.membershipLevelId);
        return <>
            {res.loading || res.membershipLevel && <div className={"item-membership" + (res.loading ? " loading" : '')}>
                <AppTextLabel component="span" i18n="community.orderregistration.basket.membershiplevel" inject={{
                    name: res.membershipLevel?.name
                }} />
            </div>}
            {applicationRule.interval && <div className="item-interval">
                <AppTextLabel component="span" i18n={"community.orderregistration.basket.interval." + applicationRule.interval}
                    inject={{
                        duration: applicationRule.intervalCount
                    }}
                />
            </div>}
        </>;
    }

    return null;
};
