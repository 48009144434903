import * as React from 'react';
import { EntityForm } from '@inwink/entityform';
import type { Entities } from '@inwink/entities/entities';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import { IFileInputManager, communityFileInputManager } from '@@api/fileapi';
import { States } from '@@services/services';
import type { IOrderRegistrationStepProps } from '@inwink/ticketing/orderregistration';
import { BlocOrderRegistrationItemsData } from '@inwink/ticketing/components/itemsdata/bloc.orderregistration.itemsdata';
import { Loader } from '@inwink/loader';
import { getDefaultValues } from '@inwink/entityform/utils';

export interface IOrderRegistrationItemsDataStepProps {
    memberformtemplate: Entities.IExtendedFieldsFormTemplate;
    membertemplate: Entities.IEntityTemplate;
    itemsFilter?: (item: any) => boolean;
    community: States.ICommunityState;
    user: States.IAppUserState;
}

interface IOrderRegistrationDataStepState {
    fileInputManager: IFileInputManager;
    showValidations: boolean;
    isLoading: boolean;
    initDataLoaded: boolean;
    userData?: any;
    defaultMemberValues: any;
}

export class OrderRegistrationItemsDataStep
    extends React.Component<IOrderRegistrationStepProps<IOrderRegistrationItemsDataStepProps>, IOrderRegistrationDataStepState> {

    itemsDataComponent = React.createRef<BlocOrderRegistrationItemsData>();

    firstItemInit = false;

    constructor(props: IOrderRegistrationStepProps<IOrderRegistrationItemsDataStepProps>) {
        super(props);
        this.state = {
            fileInputManager: communityFileInputManager(props.stepProps.community.requestManagers),
            showValidations: false,
            isLoading: true,
            initDataLoaded: false,
            defaultMemberValues: getDefaultValues(props.stepProps.memberformtemplate?.groups)
        };
    }

    getInitData = () => {
        const { user, memberformtemplate } = this.props.stepProps;
        const newData: any = {};
        const fields = this.getAllFields(memberformtemplate);

        if (user?.currentUser?.detail) {
            fields.forEach((field) => {
                if (field.type !== "Guid" && !(field as any).metadata?.isTechnical) {
                    const fieldValue = user.currentUser.detail[field.key];
                    if (fieldValue) {
                        newData[field.key] = fieldValue;
                    }
                }
            });

            const hasMembership = !!(user.currentUser.detail as any).membershipLevelId;
            const isMembershipExpired = new Date() > new Date((user.currentUser.detail as any).membershipExpiresAt);

            if ((!hasMembership || isMembershipExpired) && (user.currentUser.detail as any).inwinkcustomercontact) {
                const customerContactData = (user.currentUser.detail as any).inwinkcustomercontact;
                fields.forEach((field) => {
                    if (field.type !== "Guid" && !(field as any).metadata?.isTechnical) {
                        if (Object.prototype.hasOwnProperty.call(customerContactData, field.key)) {
                            newData[field.key] = customerContactData[field.key];
                        }
                    }
                });
            }
        }
        this.setState({ isLoading: false, initDataLoaded: true, userData: newData });
    };

    getAllFields(group: Entities.IExtendedFieldsFormGroupTemplate): Entities.IExtendedFieldsFormFieldTemplate[] {
        const fields: Entities.IExtendedFieldsFormFieldTemplate[] = [];
        if (group) {
            if (group.groups)
                group.groups.forEach(grp => {
                    const res = this.getAllFields(grp);
                    if (res) {
                        fields.push(...res);
                    }
                });
            if (group.fields)
                group.fields.forEach((fld) => {
                    fields.push(fld);
                });
        }
        return fields;
    }

    componentDidMount(): void {
        // Si l'utilisateur est connecté
        if (this.props.stepProps?.user?.currentUser?.detail) {
            this.getInitData();
        } else {
            this.setState({ isLoading: false });
        }

        return;
    }

    // updateBuyerForm = (isChecked) => {
    //     const data = this.props.orderManager.basket.items[0]?.infos[0] || {};
    //     data._isBeneficiary = isChecked;

    //     this.props.orderManager.changeBuyerData(data, this.itemsDataComponent.current.currentInnerStepIsValid());
    // };

    render() {
        // const isBeneficiary = !!this.props.orderManager.basket.buyer?._isBeneficiary;

        if (this.state.isLoading) {
            return <Loader />;
        }

        const config = this.props.stepProps?.community?.detail?.configuration;

        return <BlocOrderRegistrationItemsData
            ref={this.itemsDataComponent}
            orderManager={this.props.orderManager}
            itemsFilter={this.props.stepProps.itemsFilter}
            renderItem={(item, infos, key, onValueChanged, onValidationChanged) => {
                const template = this.props.stepProps.membertemplate;
                const formtemplate = this.props.stepProps.memberformtemplate;

                // const displayBeneficiaryCb = this.props.orderManager.basket.items.length == 1
                //     && this.props.orderManager.basket.items[0].quantity == 1;
                return <>
                    <EntityForm
                        key={`itemform_${key}_ro_${this.props.orderManager.loading}`}
                        entityFormId={`${key}`}
                        fileinputmanager={this.state.fileInputManager}
                        showValidations={this.state.showValidations}
                        displayLanguage={this.props.i18nHelper.i18n.currentLanguageCode}
                        languages={this.props.i18nHelper.i18n.supported}
                        entityName={"Member"}
                        entityTemplate={template}
                        entityFormTemplate={formtemplate?.groups}
                        computedProperties={formtemplate?.computedProperties}
                        applyComputedProperties={formtemplate?.applyComputedProperties}
                        entityValue={infos}
                        onValueChanged={(data, formstate) => {
                            onValueChanged(data, formstate.isValid);
                            // if (displayBeneficiaryCb && isBeneficiary) {
                            //     // on prefill les data du buyer que si 1 seul produit
                            //     this.updateBuyerForm(isBeneficiary);
                            // }
                        }}
                        onValidationChanged={(formstate) => onValidationChanged(formstate.isValid)}
                        forceReadOnly={this.props.orderManager.loading}
                        defaultPhoneCountryCode={config?.global?.defaultPhoneCountryCode}
                    />
                    {/* on prefill les data du buyer que si 1 seul produit */}
                    {/* {displayBeneficiaryCb && <div className="fieldwrapper type-checkbox prefill">
                        <input
                            type="checkbox"
                            checked={isBeneficiary}
                            onChange={() => this.updateBuyerForm(!isBeneficiary)}
                            id="status-checkbox"
                        />
                        <AppTextLabel
                            component="label"
                            htmlFor="status-checkbox"
                            className="forcheckbox"
                            i18n="community.orderregistration.buyer.prefill"
                        />
                    </div>
                    } */}
                </>;
            }}
            initItemData={(item, value) => {
                const defaultMemberValues = this.state.defaultMemberValues;
                let mergedValue = { ...value };

                Object.keys(defaultMemberValues).forEach((key) => {
                    if (!value[key]) {
                        mergedValue[key] = defaultMemberValues[key];
                    }
                });

                const isPass = item.category?.toLowerCase() == 'pass';
                if (!isPass && !this.firstItemInit) {
                    if (Object.keys(value).length === 0) {
                        mergedValue = { ...this.state.userData, ...mergedValue };
                    }
                    this.firstItemInit = true;
                }
                return { value: mergedValue, isValid: false };
            }}
            invalidNavEvent={() => {
                this.setState({
                    showValidations: true
                });
            }}
            renderErrors={(errors) => {
                // Message d'erreur quand le membre est déjà inscrit avec un abonnement actif
                const emailsConflicts = errors.filter(e => e.code === 710);

                if (emailsConflicts?.length > 0) {
                    return <AppTextLabel
                        component="p"
                        i18n="community.orderregistration.member.email.conflict"
                        className='error-message'
                    />;
                }

                return null;
            }}
            stepTitle="community.orderregistration.items.data.title"
        />;
    }
}