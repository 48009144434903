import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { IFrontDatasourceV3 } from '@@api/front/datasource';
import type { Entities } from '@inwink/entities/entities';

export function buyerOrderDatasourceV3(requestManager: ICommunityRequestManager)
    : Promise<IFrontDatasourceV3<Entities.IOrderCommunity>> {
    return import("@@api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/buyer/order";
        return mod.getFrontDataSource<any>(
            requestManager, url
        );
    });
}