import type { ICommunityRequestManager } from '../../../services/apiaccessprovider.definition';
import { IInwinkEntityV3QueryOptions } from '@@api/front';
import { IFrontDatasourceV3 } from '../../../api/front/datasource';
import { IMembershipLevel } from '../data';

// export function membershipLevelDatasourceV3(requestManager: ICommunityRequestManager) {
//     return import("../../../api/front/datasource").then((mod) => {
//         return mod.getFrontCommunityEntityDataSource<Entities.IContentTemplate>(
//             requestManager, "membershiplevel", "MembershipLevel"
//         );
//     });
// }

export function membershipLevelDatasourceV3(
    requestManager: ICommunityRequestManager) : Promise<IFrontDatasourceV3<IMembershipLevel>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = requestManager.communityId + "/membershiplevel";
        return mod.getFrontDataSource<IMembershipLevel>(
            requestManager, url
        );
    });
}

export function membershipQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return membershipLevelDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function membershipCount(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) {
    const targetOptions = options || {};
    return membershipLevelDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}