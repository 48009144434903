import * as React from 'react';
import * as ReactDom from 'react-dom';
import { withI18nHelper } from '@inwink/i18n';
import { connect } from 'react-redux';
import type { IEntityFormState } from '@inwink/entityform';
import { getDefaultValues } from '@inwink/entityform/utils';
import { TransitionGroup } from 'react-transition-group';
import { SimpleFadeTransition } from '@@components/transitions';
import { ModalWithFooter, ModalContent, ModalFooter } from '@@components/modals';
import type { States } from '@@services/services';
import { type IFileInputManager, communityFileInputManager } from '@@api/fileapi';
import type { ISurveyAnswerCommunityProps } from './surveyanswer.props';
import { SurveyAnswerCommunityEntityForm } from './surveyanswercommunityentityform';
import { SurveyAnswerCommunityActionBar } from '../actions/surveyanswercommunityactionbar';
import { SurveyCommunityEndPage } from './surveycommunityendpage';
import type { Entities } from '@inwink/entities/entities';
import { Loader } from '@inwink/loader';

import './surveyanswercommunitycomponent.less';

interface ISurveyAnswerCommunityState {
    fileInputManager: IFileInputManager;
    fields: Entities.IEntityTemplate;
    formTemplate: Entities.IExtendedFieldsFormTemplate;
    data: Entities.ISurveyAnswerCommunity;
    currentGroup: number;
    showValidations: boolean;
    ended: boolean;
    defaultValues: any;
    formState: IEntityFormState;
    scrollErrorInView: Date;
    isLoading: boolean;
    hasInitEmail: boolean;
}

@withI18nHelper()
class SurveyAnswerCommunityComponent extends React.Component<ISurveyAnswerCommunityProps, ISurveyAnswerCommunityState> {
    constructor(props: ISurveyAnswerCommunityProps) {
        super(props);
        let defaultValues = null;

        const entitytemplate = this.props.surveyDefinition?.template;
        const formTemplate = this.props.surveyDefinition?.formTemplate;
        if (formTemplate) {
            defaultValues = { ...getDefaultValues(formTemplate.groups) };
        }

        let isLoading = true;
        let hasInitEmail = false;
        if (this.props.user.checked) {
            if (this.props.user?.currentUser?.detail?.email && this.hasEmailInFormTemplate(formTemplate)) {
                this.setCurrentUserEmailByDefault(defaultValues);
                this.addIsReadOnlyToFormTemplateEmail(formTemplate);
                this.addIsReadOnlyToEntityTemplateEmail(entitytemplate);
            }
            isLoading = false;
            hasInitEmail = true;
        }

        this.state = {
            fileInputManager: communityFileInputManager(props.community.requestManagers),
            fields: entitytemplate,
            formTemplate: formTemplate,
            data: defaultValues,
            currentGroup: 0,
            showValidations: false,
            ended: false,
            defaultValues: defaultValues,
            formState: null,
            scrollErrorInView: new Date(),
            isLoading: isLoading,
            hasInitEmail: hasInitEmail
        };
    }

    componentDidUpdate(prevProps: ISurveyAnswerCommunityProps) {
        const userIsChecked = !prevProps.user.checked && this.props.user.checked;
        if (userIsChecked || (prevProps.user.checked && this.props.user.checked) && !this.state.hasInitEmail) {
            if (this.props.user?.currentUser?.detail?.email) {
                const formTemplate = this.state.formTemplate;
                const fields = this.state.fields;
                const data = this.state.data;

                if (this.hasEmailInFormTemplate(formTemplate)) {
                    this.setCurrentUserEmailByDefault(data);
                    this.addIsReadOnlyToFormTemplateEmail(formTemplate);
                    this.addIsReadOnlyToEntityTemplateEmail(fields);
                    this.setState({
                        data: data,
                        formTemplate: formTemplate,
                        fields: fields,
                        isLoading: false,
                        hasInitEmail: true
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        hasInitEmail: true
                    });
                }
            } else {
                this.setState({
                    isLoading: false,
                    hasInitEmail: true
                });
            }
        }
    }

    hasEmailInFormTemplate(
        template: Entities.IExtendedFieldsFormTemplate
        | Entities.IExtendedFieldsFormGroupTemplate | Entities.IExtendedFieldsFormGroupTemplate[]
    ): boolean {
        if (Array.isArray(template)) {
            return template.some((t: Entities.IExtendedFieldsFormGroupTemplate) => this.hasEmailInFormTemplate(t));
        } else if (template && typeof template === 'object') {
            if ('fields' in template && template.fields) {
                if (template.fields.some(field => field.key === "email")) {
                    return true;
                }
            }
            if ('groups' in template && template.groups) {
                return template.groups.some((group) => this.hasEmailInFormTemplate(group));
            }
        }
        return false;
    }

    setCurrentUserEmailByDefault(data: Entities.ISurveyAnswerCommunity) {
        data.email = this.props.user.currentUser.detail.email;
    }

    addIsReadOnlyToFormTemplateEmail(template: Entities.IExtendedFieldsFormTemplate
    | Entities.IExtendedFieldsFormGroupTemplate | Entities.IExtendedFieldsFormGroupTemplate[]) {
        if (Array.isArray(template)) {
            template.forEach((t) => this.addIsReadOnlyToFormTemplateEmail(t));
        } else if (template && typeof template === 'object') {
            const fields = (template as Entities.IExtendedFieldsFormGroupTemplate).fields;
            if (fields) {
                fields.forEach(field => {
                    if (field.key === "email") {
                        field.isReadOnly = true;
                    }
                });
            }
            if (template.groups?.length > 0) {
                template.groups.forEach((group) => this.addIsReadOnlyToFormTemplateEmail(group));
            }
        }
    }

    addIsReadOnlyToEntityTemplateEmail(entitytemplate: Entities.IEntityTemplate) {
        if (entitytemplate?.fields) {
            entitytemplate.fields.forEach((field) => {
                if (field.key === "email") {
                    field.isReadOnly = true;
                }
            });
        }
    }

    validateCurrentGroup = () => {
        if (!this.state.formState?.validations || this.state.formState?.validations.length) {
            this.setState({ showValidations: true, scrollErrorInView: new Date() });
        } else {
            this.setState({ showValidations: false });
        }
    };

    updateData = (data: Entities.ISurveyAnswerCommunity, formstate: IEntityFormState) => {
        this.setState({
            data: data,
            formState: formstate
        });
    };

    updateValidation = (formstate: IEntityFormState) => {
        this.setState({ formState: formstate });
    };

    sendSurvey = (arg: React.MouseEvent<any>) => {
        arg?.preventDefault();
        arg?.stopPropagation();

        if (this.state.formState?.validations.length === 0) {
            return this.props.onValidate(this.state.data).then(() => {
                this.setState({ ended: true });
            });
        } else {
            this.setState({ showValidations: true, scrollErrorInView: new Date() });
        }
    };

    currentGroupChanged = () => {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDom.findDOMNode(this) as HTMLElement;
        if (node) {
            node.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    navigateToGroup = (group) => {
        this.setState({
            currentGroup: group,
            showValidations: false,
            formState: null,
            scrollErrorInView: new Date()
        }, this.currentGroupChanged);
    };

    render() {
        let surveyContent;
        if (this.state.isLoading) {
            return <Loader />;
        }
        if (this.state.ended) {
            surveyContent = <SimpleFadeTransition exit={false}>
                <SurveyCommunityEndPage
                    {...this.props}
                    i18nHelper={this.props.i18nHelper}
                    data={this.state.data}
                />
            </SimpleFadeTransition>;
        } else if (this.state.fields && this.state.formTemplate) {
            surveyContent = <SimpleFadeTransition exit={false}>
                <SurveyAnswerCommunityEntityForm
                    {...this.props}
                    i18nHelper={this.props.i18nHelper}
                    key={"page" + this.state.currentGroup}
                    onChange={this.updateData}
                    fileInputManager={this.state.fileInputManager}
                    currentGroup={this.state.currentGroup}
                    entityTemplate={this.state.fields}
                    entityFormTemplate={this.state.formTemplate}
                    showValidations={this.state.showValidations}
                    onValidationChange={this.updateValidation}
                    data={this.state.data}
                    defaultValues={this.state.defaultValues}
                    scrollErrorInView={this.state.scrollErrorInView}
                />
            </SimpleFadeTransition>;
        }

        return <ModalWithFooter className="surveyanswercommunity">
            <ModalContent>
                <TransitionGroup component="section" className="surveycontent">
                    {surveyContent}
                </TransitionGroup>
            </ModalContent>
            <ModalFooter>
                <SurveyAnswerCommunityActionBar
                    {...this.props}
                    i18nHelper={this.props.i18nHelper}
                    formstate={this.state.formState}
                    ended={this.state.ended}
                    onValidateGroup={this.validateCurrentGroup}
                    onNavigateTo={this.navigateToGroup}
                    currentGroup={this.state.currentGroup}
                    entityTemplate={this.state.fields}
                    entityFormTemplate={this.state.formTemplate}
                    onValidateAnswer={this.sendSurvey}
                    data={this.state.data}
                />
            </ModalFooter>
        </ModalWithFooter>;
    }
}

function mapDispatchToProps() {
    return {
    };
}

function mapStateToProps(state: States.IAppState) {
    return {
        user: state.user,
        community: state.community,
        i18n: state.i18n
    };
}

export const SurveyAnswerCommunity: new (any) => React.Component<ISurveyAnswerCommunityProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(SurveyAnswerCommunityComponent as any) as any;
(SurveyAnswerCommunity as any).className = "appmodal-surveyanswercommunity";
