import * as React from 'react';
import { EntityForm, type IEntityFormState } from '@inwink/entityform';
import type { Entities } from '@inwink/entities/entities';
import type { IOrderRegistrationBasket, IOrderRegistrationBasketItem } from '@inwink/ticketing/definitions';
import type { IOrderRegistrationStepProps } from '@inwink/ticketing/orderregistration';
import { OrderManager } from '@inwink/ticketing/ordermanager';
import type { States } from '@@services/services';
import { AppTextLabel } from '@@services/i18nservice';
import { getDefaultValues } from '@inwink/entityform/utils';
import { Loader } from '@inwink/loader';

export interface IOrderRegistrationOrderDataStepProps {
    buyerformtemplate: Entities.IExtendedFieldsFormTemplate;
    buyertemplate: Entities.IEntityTemplate;
    community: States.ICommunityState;
}

interface IOrderRegistrationDataStepState {
    isLoading: boolean;
    showValidations: boolean;
}

const defaultstate: Partial<IEntityFormState> = { isValid: false, validations: [] };

export function orderRegistrationOrderDataStepFormatBasketToCheck(orderManager: OrderManager): IOrderRegistrationBasket {
    return {
        discountCodes: orderManager.basket.discountCodes,
        items: orderManager.basket.items.map((i) => ({
            productId: i.productId,
            quantity: i.quantity,
            infos: orderManager.isItemsDataStepValid ? i.infos : []
        } as IOrderRegistrationBasketItem)),
        buyer: orderManager.basket.buyer,
        order: orderManager.basket.order
    } as IOrderRegistrationBasket;
}

export class OrderRegistrationOrderDataStep
    extends React.Component<IOrderRegistrationStepProps<IOrderRegistrationOrderDataStepProps>, IOrderRegistrationDataStepState> {
    buyerstate: Partial<IEntityFormState> = defaultstate;

    orderDataPageRef: React.RefObject<HTMLDivElement>;

    constructor(props: IOrderRegistrationStepProps<IOrderRegistrationOrderDataStepProps>) {
        super(props);
        this.state = {
            isLoading: true,
            showValidations: false
        };
        this.orderDataPageRef = React.createRef();
    }

    componentDidMount(): void {
        this.props.orderManager.goNextListener = () => {
            if (!this.currentInnerStepIsValid()) {
                this.setState({ showValidations: true });
                this.scrollToFirstError();
                return false;
            }
            return true;
        };

        this.props.orderManager.updateStepValidity(true);

        if (this.props.orderManager.isItemsDataStepValid && (this.props.orderManager.basket.buyer === null
            || Object.keys(this.props.orderManager.basket.buyer).length === 0)) {
            const itemData = this.props.orderManager.basket.items[0]?.infos[0];

            if (itemData) {
                this.props.orderManager.changeBuyerData(Object.assign({}, this.props.orderManager.basket.buyer, itemData),
                    true);
            }
        }

        this.getInitData();

        return;
    }

    scrollToFirstError = () => {
        const errorElement = document.querySelector('.has-error');
        const pageElt = this.orderDataPageRef.current;
        if (errorElement && errorElement instanceof HTMLElement) {
            errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            errorElement.focus();
        } else if (pageElt) {
            pageElt.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    getInitData = () => {
        const { buyerformtemplate } = this.props.stepProps;
        const newBuyerData: any = Object.assign({}, this.props.orderManager.basket.buyer);
    
        // Récupérer les valeurs par défaut pour les formulaires d'acheteur (buyer)
        const defaultBuyerValues = getDefaultValues(buyerformtemplate.groups);
    
        let changeBuyerData;
        if (defaultBuyerValues) {
            let assigned;
            Object.keys(defaultBuyerValues).forEach((key) => {
                if (!newBuyerData[key]) {
                    newBuyerData[key] = defaultBuyerValues[key];
                    assigned = true;
                }
            });
            changeBuyerData = assigned;
        }
        
        if (changeBuyerData) {
            this.props.orderManager.changeBuyerData(newBuyerData, true);
        }
    
        this.setState({ isLoading: false });
    };

    currentInnerStepIsValid = () => {
        return this.buyerstate.isValid;
    };

    buyerChanged = (buyer, formstate: IEntityFormState) => {
        this.buyerstate = formstate;
        this.props.orderManager.changeBuyerData(Object.assign({}, this.props.orderManager.basket.buyer, buyer),
            true);
    };

    buyerValidationChanged = (formstate: IEntityFormState) => {
        this.buyerstate = formstate;
        this.props.orderManager.updateStepValidity(true);
    };

    render() {

        if (this.state.isLoading) {
            return <Loader />;
        }
        
        const template = this.props.stepProps.buyertemplate;
        const formtemplate = this.props.stepProps.buyerformtemplate;
        const buyerTemplateRender = <EntityForm
            key={`buyerform_ro_${this.props.orderManager.loading}`}
            id="buyerform"
            entityFormId="buyerform"
            showValidations={this.state.showValidations}
            displayLanguage={this.props.i18nHelper.i18n.currentLanguageCode}
            languages={this.props.i18nHelper.i18n.supported}
            entityName="Buyer"
            entityTemplate={template}
            entityFormTemplate={formtemplate?.groups}
            computedProperties={formtemplate?.computedProperties}
            applyComputedProperties={formtemplate?.applyComputedProperties}
            entityValue={this.props.orderManager.basket.buyer}
            onValueChanged={this.buyerChanged}
            onValidationChanged={this.buyerValidationChanged}
            defaultPhoneCountryCode={this.props.stepProps?.community?.detail?.configuration?.global?.defaultPhoneCountryCode}
        />;
        return <div ref={this.orderDataPageRef} className='step-content order-data'>
            <AppTextLabel
                className="step-title order-data-title"
                i18n="community.orderregistration.order.data.title"
                component='h3' />
            <div className='item bloc-lightborder'>
                {buyerTemplateRender}
            </div>
        </div>;
    }
}
