import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { IFrontDatasourceV3, IInwinkEntityQueryResult } from '@@api/front/datasource';
import type { Entities } from '@inwink/entities/entities';
import { defaultPostHeaderForJsonData } from '@@api/index';

export function surveyDatasourceV3(requestManager: ICommunityRequestManager) :
Promise<IFrontDatasourceV3<Entities.ISurveyCommunity>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/surveycommunity";
        return mod.getFrontDataSource<Entities.ISurveyCommunity>(
            requestManager, url
        );
    });
}

export function surveyCommunityQuery(
    requestManager: ICommunityRequestManager,
    options?: IInwinkEntityV3QueryOptions
) : Promise<IInwinkEntityQueryResult<Entities.ISurveyCommunity>> {
    const targetOptions = options || {};
    return surveyDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function createSurveyAnswerCommunity(
    requestManager: ICommunityRequestManager,
    surveyId: string,
    answer: Entities.ISurveyAnswerCommunity
) : Promise<Entities.ISurveyAnswerCommunity> {
    return requestManager.postCommunityJson(`surveycommunity/${surveyId}/answer`,
        JSON.stringify({
            entity: answer
        }), defaultPostHeaderForJsonData);
}
