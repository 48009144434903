import * as React from 'react';
import { Loader } from '@inwink/loader';
import type { Entities } from '@inwink/entities/entities';
import { type IDynamicBlocBaseProps, DynamicBloc } from '@@components/dynamicpage/common';
import { BlocTitle } from "@@components/dynamicpage/common.title";
import { AppTextLabel } from '@@services/i18nservice';
import { createSurveyAnswerCommunity, surveyCommunityQuery } from '@@modules/community/api/surveycommunity';
import { SurveyAnswerCommunity } from '../components/surveyanswercommunitycomponent';

import './bloc.surveycommunity.less';

interface IBlocSurveyCommunityComponentProps extends IDynamicBlocBaseProps {
}

interface IBlocSurveyCommunityComponentState {
    survey: Entities.ISurveyCommunity;
    loading: boolean;
    datastamp: Date;
}

export class BlocSurveyCommunityComponent extends
    React.Component<IBlocSurveyCommunityComponentProps, IBlocSurveyCommunityComponentState> {
    constructor(props: IBlocSurveyCommunityComponentProps) {
        super(props);
        this.state = {
            loading: true,
            datastamp: new Date(),
            survey: null
        };
    }

    componentDidMount() {
        if (this.state.loading) {
            if (this.props.template.properties.surveyId) {
                surveyCommunityQuery(this.props.community.requestManagers.apiFront,
                    {
                        filters: { $or: [
                            { id: this.props.template.properties.surveyId },
                            { surveyId: this.props.template.properties.surveyId }
                        ]},
                        selects: {
                            $all: true
                        }
                    }
                ).then((res) => {
                    if (res?.data?.length) {
                        const survey = res.data?.[0];
                        this.setState({
                            survey: survey,
                            loading: false
                        });
                    }
                }, (err) => {
                    this.setState({ loading: false });
                    console.error("error loading surveys", err);
                });
            } else {
                this.setState({ loading: false });
            }
        }
    }

    validateSurvey = (data: Entities.ISurveyAnswerCommunity) => {
        const dataToSend = data;
        dataToSend.creationDate = new Date();
        return createSurveyAnswerCommunity(this.props.community.requestManagers.apiFront,
            this.state.survey.id,
            dataToSend);
    };

    render() {
        let content;
        if (this.state.loading) {
            content = <Loader />;
        } else if (this.state.survey) {
            content = <SurveyAnswerCommunity
                datastamp={this.state.datastamp}
                surveyDefinition={this.state.survey}
                onCompleted={() => { }}
                disableCloseBtn={true}
                onValidate={this.validateSurvey}
            />;
        } else {
            content = <AppTextLabel i18n="error.noform" component="div" />;
        }

        return <div className='bloc-surveycommunity'>
            <DynamicBloc {...this.props}>
                <BlocTitle {...this.props} />
                <div className="bloc-content">
                    {content}
                </div>
            </DynamicBloc>
        </div>;
    }
}
