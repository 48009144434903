import * as React from 'react';
import { withUrlService } from "@@components/urlstatecontext";
import type { Entities } from "@inwink/entities/entities";
import type { IEntityFormState } from "@inwink/entityform";
import type { ISurveyAnswerCommunityProps } from '../components/surveyanswer.props';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { Button } from '@inwink/buttons';

interface ISurveyAnswerCommunityActionBarProps extends ISurveyAnswerCommunityProps {
    entityTemplate: Entities.IEntityTemplate;
    entityFormTemplate: Entities.IExtendedFieldsFormTemplate;
    currentGroup: number;
    data: Entities.ISurveyAnswerCommunity;
}

interface ISurveyAnswerCommunityHeaderProps extends ISurveyAnswerCommunityActionBarProps {
    onNavigateTo: (group) => void;
    onValidateGroup: () => void;
    ended: boolean;
    formstate: IEntityFormState;
    onValidateAnswer: (arg: React.MouseEvent<any>) => void;
    i18nHelper: Entities.i18nHelper;
}

@withUrlService()
export class SurveyAnswerCommunityActionBar extends React.Component<ISurveyAnswerCommunityHeaderProps, any> {
    navigateTo(idx: number) {
        if (idx < this.props.currentGroup) {
            this.props.onNavigateTo(idx);
            return;
        }

        if (this.props.formstate?.validations && this.props.formstate?.validations.length === 0) {
            this.props.onNavigateTo(idx);
        } else {
            this.props.onValidateGroup();
        }
    }

    getLabelFromKey(tradKey: string) : string {
        const i18n = this.props.i18nHelper;
        return i18n.translate(tradKey);    
    }

    renderPrevButton() {
        if (this.props.currentGroup > 0) {
            const btnprevlabel = this.getLabelFromKey("surveycommunity.action.button.prev.label");
            return <Button
                className="prev lightbtn"
                onClick={() => this.navigateTo(this.props.currentGroup - 1)}
            >
                <i className="inwink-chevron-left" />
                <span className="label">{btnprevlabel}</span>
            </Button>;
        }
        return null;
    }

    renderNextButton() {
        if (this.props.currentGroup < this.props.entityFormTemplate.groups.length - 1) {
            const btnnextlabel = this.getLabelFromKey("surveycommunity.action.button.next.label");
            return <Button
                className="next lightbtn"
                onClick={() => this.navigateTo(this.props.currentGroup + 1)}
            >
                <span className="label">{btnnextlabel}</span> <i className="inwink-chevron-right" />
            </Button>;
        }
        return null;
    }

    render() {
        let prev;
        let next;
        const currentGroup = this.props.entityFormTemplate?.groups
            ? this.props.entityFormTemplate.groups[this.props.currentGroup] : null;
        if (!currentGroup) return null;

        const btncloseLabel = this.getLabelFromKey("surveycommunity.action.button.close.label");
        const btnvalidatelabel = this.getLabelFromKey("surveycommunity.action.button.validate.label");

        if (this.props.ended) {
            if (!this.props.disableCloseBtn) {
                next = <AsyncButton
                    className="send"
                    onClick={(arg) => this.props.onCompleted(arg)}>
                    {btncloseLabel}
                </AsyncButton>;
            }
        } else {
            if (this.props.entityFormTemplate.groups.length > 1) {
                prev = this.renderPrevButton();
                next = this.renderNextButton();
            }

            if (!next) {
                next = <AsyncButton
                    className="send"
                    onClick={(arg) => this.props.onValidateAnswer(arg)}>
                    {btnvalidatelabel}
                </AsyncButton>;
            }
        }

        return <>
            {prev}
            <div className="middle" />
            {next}
        </>;
    }
}