import * as React from 'react';
import type { Entities } from "@inwink/entities/entities";
import { ItemTemplate } from "@inwink/itemtemplate";
import type { States } from '@@services/services';

interface ISurveyAnswerPageProps  {
    surveyDefinition: Entities.ISurveyCommunity;
    data?: Entities.ISurveyAnswerCommunity;
    user?: States.IAppUserState;
    community?: States.ICommunityState;
    urlservice?: States.IAppUrlContext;
    i18n?: States.i18nState;
    i18nHelper?: Entities.i18nHelper;
}

export function SurveyCommunityEndPage(props: ISurveyAnswerPageProps) {
    let content;

    if (props.surveyDefinition.surveyEndTemplate) {
        const data = {
            entity: props.data,
            eventstate: null,
            event: null,
            communitystate: props.community,
            community: props.community.detail,
            userstate: props.user,
            user: props.user.currentUser ? props.user.currentUser.detail : null,
            i18nstate: props.i18nHelper,
            fieldtemplate: null
        } as Entities.IPageDataContext;

        content = <ItemTemplate
            data={props.data}
            datacontext={data}
            user={props.user.currentUser}
            i18nHelper={props.i18nHelper}
            page={null}
            template={props.surveyDefinition.surveyEndTemplate}
            urlservice={props.urlservice}
            theme={null}
        />;
    } else {
        const i18n = props.i18nHelper;
        content = <div className="message">
            {i18n.translate("surveycommunity.endmessage")}
        </div>;
    }

    return <div className="surveygroup endpage bloc-defaultbg">
        {content}
    </div>;
}