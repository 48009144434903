import * as React from 'react';
import { ItemTemplate } from '@@components/templates/itemtemplate';
import type { IOrderRegistrationItem } from '@inwink/ticketing/definitions';
import type { IProductItemsProps } from './product.items';
import { IItemsProperties } from '@@services/itemshelpers';
import { VisualTheme } from '@inwink/entities/visualtheme';

interface IProductItemProps extends IProductItemsProps {
    product: IOrderRegistrationItem;
    itemtemplate: VisualTheme.IItemTemplate;
    properties: IItemsProperties;
}

export class ProductItem extends React.Component<IProductItemProps, any> {
    goNextStep = () => {
        const { orderManager } = this.props;

        if (orderManager.currentStepIsValid) {
            orderManager.goNext();
        }
    };

    handleStepBasket = (item) => {
        const { orderManager, stepProps } = this.props;

        // Initialiser ou récupérer l'état du panier pour chaque step
        const stepsBasket = (orderManager.basket as any)._stepsBasket || {};
        const stepId = stepProps.stepId;
        const currentTransitItemId = stepsBasket[stepId];
            
        if (currentTransitItemId) {
            if (currentTransitItemId === item.id) {
                // Si l'item cliqué est le même que celui du panier de l'étape, ajouter une unité sans vider le panier
                orderManager.changeItemQuantity(item.id, 1, true, false).then(this.goNextStep);
            } else {
                // Sinon, mettre à jour le panier de l'étape et remplacer l'article
                stepsBasket[stepId] = item.id;
                orderManager.changeItemQuantity(currentTransitItemId, 0, true, false);
                orderManager.changeItemQuantity(item.id, 1, true, false).then(this.goNextStep);
            }
        } else {
            // panier d'étape vide
            stepsBasket[stepId] = item.id;
            orderManager.changeItemQuantity(item.id, 1, true, false).then(this.goNextStep);
        }
        // Mettre à jour le panier d'étape
        (orderManager.basket as any)._stepsBasket = stepsBasket;
    };

    handleMonoProductClick = (item: IOrderRegistrationItem) => {
        const { orderManager } = this.props;
        const basket = orderManager?.basket?.items || [];
        const itemInBasket = basket.some(basketItem => basketItem.productId === item.id);
        const hasMultipleProductSteps = steps => steps.filter(step => step.stepType === "products").length > 1;
    
        // Vérifier s'il y a plusieurs étapes de produits
        if (hasMultipleProductSteps(orderManager.steps)) {
            this.handleStepBasket(item);
        } else {
            if (itemInBasket) {
                // Si l'item est déjà dans le panier, passer directement à l'étape suivante
                this.goNextStep();
            } else {
                // Sinon, ajouter l'item au panier et vider les autres articles
                orderManager.changeItemQuantity(item.id, 1, true, true).then(this.goNextStep);
            }
        }
    };    

    isItemSelected = () => {
        const { product, stepProps, orderManager, orderRegistrationConfig } = this.props;

        if (!orderRegistrationConfig?.enableMonoProduct) {
            return false;
        }

        const stepsBasket = (orderManager.basket as any)?._stepsBasket;
        
        if (stepsBasket) {
            const stepId = stepProps.stepId;
            const currentTransitItemId = stepsBasket[stepId];
            return currentTransitItemId === product.id;
        } else {
            const basket = orderManager?.basket?.items || [];
            return basket.length > 0 && basket[0].productId === product.id;
        }
    };
    

    render() {
        const { product, itemtemplate, stepProps,
            properties, orderManager, orderRegistrationConfig } = this.props;

        return (<div
            className={this.isItemSelected() ? "inwink-item selected" : "inwink-item"}
            id={'item-' + product.id}
        >
            <ItemTemplate
                template={itemtemplate?.template}
                datacontext={{
                    ...this.props.datacontext,
                    entity: product,
                    templateOptions: itemtemplate?.templateOptions,
                    customcontext: {
                        orderManager: orderManager,
                        stepId: stepProps.stepId,
                        templateConfig: stepProps.config,
                        templateProperties: properties,
                        enableMonoProduct: orderRegistrationConfig?.enableMonoProduct
                    }
                }}
                data={product}
                onClick={orderRegistrationConfig?.enableMonoProduct && !stepProps.isAdditionalProduct 
                    ? () => this.handleMonoProductClick(product) 
                    : null
                }
            />
        </div>);
    }
}
