import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n';
import { networkingActions } from '@@community/features/networking/actions';
import { AppTextLabel } from '@@services/i18nservice';
import { 
    hasValidMembershipLevel, 
    type IRequestItem, 
    type IMember,
    type IMemberContact, 
    type IMemberContactRequest 
} from '@@community/data';
import { getMemberDetail } from '@@modules/community/api/memberinfo';
import { bindActionCreators } from 'redux';
import { ReadMore } from '@inwink/readmore';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';

import type { Entities } from '@inwink/entities/entities';
import type { States } from '@@services/services';

interface IMemberContactActionsBaseProps {
    community: States.ICommunityState;
    i18nHelper: Entities.i18nHelper;
    currentMember: IMember;
    networkingActions?: typeof networkingActions;
    targetMemberId: string;
    onStatusChanged?: () => void;
}

interface IMemberContactActionsBaseState {
    member?: IMember;
    contact?: IMemberContact;
    contactRequest?: IMemberContactRequest;
    btnText?: string;
    btnClass?: string;
    isContact: boolean;
    contactRequestReceived?: IRequestItem;
}

@connectwith(null, (dispatch) => {
    return {
        networkingActions: bindActionCreators(networkingActions, dispatch)
    };
})

@withI18nHelper()
export class MemberContactActionsBaseComponent
    extends React.Component<IMemberContactActionsBaseProps, IMemberContactActionsBaseState> {

    constructor(props: IMemberContactActionsBaseProps) {
        super(props);
        
        this.state = {
            contactRequestReceived: null,
            contact: null,
            btnText: "community.member.contact.status.nocontact",
            btnClass: "nocontact",
            isContact: false
        };
    }

    hasDisabledContactRequest = () => {
        const {
            community
        } = this.props;

        return community?.detail?.configuration?.membersNetworking?.disableContactRequest;
    };

    memberClicked = (event: React.MouseEvent<any>) => {
        event.preventDefault();
    
        const {
            i18nHelper,
            targetMemberId,
            onStatusChanged
        } = this.props;
    
        const { contact, member } = this.state;
        const disableContactRequest = this.hasDisabledContactRequest();

        const discussionId = contact?.discussionId;
        const shouldShowMember = disableContactRequest || !discussionId;
    
        if (contact || disableContactRequest) {
            this.props.networkingActions.showDiscussion(
                event.currentTarget,
                i18nHelper,
                discussionId,
                null,
                shouldShowMember && member,
                shouldShowMember && this.refreshMemberDetail,
                this.onDiscussionChanged
            );
        } else {
            this.props.networkingActions.showMember(
                i18nHelper,
                event.currentTarget,
                targetMemberId,
                {
                    onCompleted: (res, popover) => {
                        this.refreshMemberDetail();
                        onStatusChanged?.();
                        popover?.close();
                    }
                }
            );
        }
    };
    
    componentDidMount() {
        if (this.isAllowToNetWork()) {
            this.refreshMemberDetail();
        }
    }

    isAllowToNetWork = () => {
        const { currentMember } = this.props;

        const { membershipIsValid } = hasValidMembershipLevel(currentMember);
        const networkingStatus = currentMember?.membershipLevel?.configuration?.networking;

        return membershipIsValid && networkingStatus?.allowed;
    };

    onDiscussionChanged = () => {
        const disableContactRequest = this.hasDisabledContactRequest();
        if (disableContactRequest) {
            this.props.onStatusChanged?.();
        }
    };

    updateDisplayTextAndBtnClass = (res) => {
        const isContact = res.contact?.memberId ? true : false;
        const contactRequestReceived = res?.member?.id === res?.contactRequest?.memberId ? res.contactRequest : null;
        const contactRequest = res?.contactRequest;
        const disableContactRequest = this.hasDisabledContactRequest();
    
        if (contactRequestReceived) {
            this.setState({
                ...res,
                contactRequestReceived,
                btnText: "community.member.contact.status.accepted",
                btnClass: "accepted",
                isContact: false
            });
            return;
        }
    
        if (disableContactRequest) {
            this.setState({
                ...res,
                btnText: "community.member.contact.status.accepted",
                btnClass: "accepted",
                isContact: true
            });
        } else if (isContact) {
            this.setState({
                ...res,
                btnText: "community.member.contact.status.accepted",
                btnClass: "accepted",
                isContact: true
            });
        } else if (contactRequest) {
            switch (contactRequest?.status) {
                case "Accepted":
                    this.setState({
                        ...res,
                        btnText: "community.member.contact.status.accepted",
                        btnClass: "accepted",
                        isContact: true
                    });
                    break;
                case "Pending":
                    this.setState({
                        ...res,
                        btnText: "community.member.contact.status.pending",
                        btnClass: "pending",
                        isContact: false
                    });
                    break;
                default:
                    break;
            }
        } else if (!contactRequest && !isContact && !disableContactRequest && !contactRequestReceived) {
            this.setState({
                ...res,
                contact: null,
                btnText: "community.member.contact.status.nocontact",
                btnClass: "nocontact",
                isContact: false
            });
        }
    };

    handleDeleteContact = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        const { showDeleteContact } = this.props.networkingActions;

        showDeleteContact(
            this.props.i18nHelper,
            this.props.targetMemberId,
            this.state.member,
            (res) => {
                if (res?.deleted) {
                    this.refreshMemberDetail();
                    this.props.onStatusChanged?.();
                }
            }
        );
    };

    acceptContact = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();

        import("@@community/api/contacts").then((mod) => {
            mod.acceptContactRequest(
                this.props.community.requestManagers.apiFront,
                this.state.contactRequestReceived?.id
            ).then(() => {
                this.refreshMemberDetail();
                this.props.onStatusChanged?.();
            });
        });
    };

    declineContact = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();

        import("@@community/api/contacts").then((mod) => {
            mod.declineContactRequest(
                this.props.community.requestManagers.apiFront,
                this.state.contactRequestReceived?.id
            ).then(() => {
                this.refreshMemberDetail();
                this.props.onStatusChanged?.();
            });
        });
    };

    refreshMemberDetail = () => {
        return getMemberDetail(
            this.props.community.requestManagers.apiFront, this.props.community?.communityid, this.props.targetMemberId
        ).then((res) => {
            this.updateDisplayTextAndBtnClass(res);
        });
    };

    render() {
        if (!this.isAllowToNetWork()) return null;

        const disabledContactRequest = this.hasDisabledContactRequest();
        const contactRequestReceived = this.state.contactRequestReceived;

        const requestMessage = contactRequestReceived?.text;
        if ((contactRequestReceived && contactRequestReceived.status !== "Declined") && !disabledContactRequest) {
            return (
                <div className="contact-request-message">
                    {requestMessage && (
                        <>
                            <hr />
                            <section>
                                <div className="message-request">
                                    <p
                                        className="bloc-lighttext"
                                        title={requestMessage}
                                    >
                                        <ReadMore
                                            maxLength={90}
                                            text={requestMessage || ""}
                                        />
                                    </p>
                                </div>
                            </section>
                        </>
                    )}
                    <div className="membercontactactions">
                        <button
                            type="button"
                            className="contactbtn acceptbtn"
                            onClick={this.acceptContact}
                        >
                            <AppTextLabel i18n="community.member.contact.accept" />
                        </button>
                        <button
                            type="button"
                            className="contactbtn declinebtn lightbtn"
                            onClick={this.declineContact}
                        >
                            <AppTextLabel i18n="community.member.contact.decline" />
                        </button>
                    </div>
                </div>
            );
        }

        return this.props.targetMemberId !== this.props.currentMember?.id && (
            <div className="membercontactactions">
                <button
                    type="button"
                    className={`contactbtn ${this.state.btnClass}`}
                    onClick={this.memberClicked}>
                    <AppTextLabel i18n={this.state.btnText} />
                </button>
                {this.state.isContact && !disabledContactRequest && (
                    <div
                        className={`bloc-accent member-deletebtn`}
                        onClick={this.handleDeleteContact}
                    >
                        <AppTextLabel i18n="community.member.contact.delete" />
                    </div>
                )}
            </div>
        );
    }
}