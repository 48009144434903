import type { ICommunityRequests } from '@@services/apiaccessprovider.definition';
import { defaultPostHeaderForJsonData } from 'api/front';
import type { IOrderRegistrationBasketItem, 
    IOrderDatasourceCheckResult, ICheckDiscountCodeResult } from '@inwink/ticketing/definitions';
import { stringify } from '@inwink/utils/querystring';

export function checkDiscountCode(requestManager: ICommunityRequests, discountCode: string): Promise<ICheckDiscountCodeResult> {
    return requestManager.apiFront.postJson(`/community/${requestManager.communityid}/order/check-discount-code`,
        JSON.stringify({ discountCode: discountCode }), defaultPostHeaderForJsonData);
}

export interface IOrderItem {
    productId: string;
    infos: any;
    quantity: number;
}

export function createOrder(requestManager: ICommunityRequests, paymentMode: string, order: any, discountCodes: string[],
    buyer: any, items: IOrderRegistrationBasketItem[], returnurl: string, shardTitle: string, contentTemplateId?: string, 
    blocId?: string, isOrderFinalized?: boolean) {
    return requestManager.apiFront.postJson(`/community/${requestManager.communityid}/order`, JSON.stringify({
        paymentMode: paymentMode,
        order: order,
        buyerinfos: buyer,
        discountCodes: discountCodes,
        items: items,
        baseReturnUrl: returnurl,
        shardTitle: shardTitle,
        ticketingBlocTemplateInfos: {
            templateId: contentTemplateId,
            blocId: blocId
        },
        isOrderFinalized: isOrderFinalized 
    }), defaultPostHeaderForJsonData);
}

export function updateOrder(requestManager: ICommunityRequests, paymentMode: string, order: any, 
    discountCodes: string[], buyer: any, items: IOrderRegistrationBasketItem[], returnurl: string, 
    shardTitle: string, contentTemplateId?: string, blocId?: string, isOrderFinalized?: boolean) {
    return requestManager.apiFront.postJson(`/community/${requestManager.communityid}/order/update`, JSON.stringify({
        paymentMode: paymentMode,
        order: order,
        buyerinfos: buyer,
        discountCodes: discountCodes,
        items: items,
        baseReturnUrl: returnurl,
        shardTitle: shardTitle,
        ticketingBlocTemplateInfos: {
            templateId: contentTemplateId,
            blocId: blocId
        },
        isOrderFinalized: isOrderFinalized 
    }), defaultPostHeaderForJsonData);
}

export function cancelOrder(requestManager: ICommunityRequests, orderId: string, dataInfo: string, sign: string) {  
    const searchParams = stringify({
        ["data-info"]: dataInfo,
        sign
    });

    return requestManager.apiFront.postJson(
        `/community/${requestManager.communityid}/order/cancel?${searchParams}`,
        JSON.stringify({ orderId: orderId }),
        defaultPostHeaderForJsonData
    );
}

export function proceedOnlinePayment(requestManager: ICommunityRequests, orderId: string, shardTitle: string, returnurl: string) {
    return requestManager.apiFront.postJson(`/community/${requestManager.communityid}/order/payment`, JSON.stringify({
        orderId: orderId,
        shardTitle: shardTitle,
        baseReturnUrl: returnurl
    }), defaultPostHeaderForJsonData);
}

export function validateOrder(requestManager: ICommunityRequests, action: string, orderID: string, orderArguments: any) {
    return requestManager.apiFront.postJson(`/community/${requestManager.communityid}/order/validate`, JSON.stringify({
        orderID: orderID,
        action: action,
        orderArguments: orderArguments
    }), defaultPostHeaderForJsonData);
}

export interface ICheckOrderResult {
    hasErrors: boolean;
    basePriceHt: number;
    discountAmount: number;
    basePriceTtc: number;
    discountPriceHt: number;
    discountPriceTtc: number;
    vatAmount: number;
    errors: [];
}

export function checkOrder(requestManager: ICommunityRequests, paymentMode: string, order: any, discountCodes: string[],
    buyer: any, items: IOrderItem[], returnurl: string, contentTemplateId?: string, blocId?: string): Promise<ICheckOrderResult> {
    return requestManager.apiFront.postJson(`/community/${requestManager.communityid}/order/check`, JSON.stringify({
        paymentMode: paymentMode,
        order: order,
        buyerinfos: buyer,
        discountCodes: discountCodes,
        items: items,
        baseReturnUrl: returnurl,
        ticketingBlocTemplateInfos: {
            templateId: contentTemplateId,
            blocId: blocId
        },
    }), defaultPostHeaderForJsonData);
}

export function getOrderBag(requestManager: ICommunityRequests, orderId: string, dataInfo: string,
    sign: string, contentTemplateId: string, blocId: string): Promise<IOrderDatasourceCheckResult> {
    const searchParams = stringify({
        orderId,
        contentTemplateId,
        blocId,
        ["data-info"]: dataInfo,
        sign
    });

    return requestManager.apiFront.getJson(`/community/${requestManager.communityid}/order/orderbag?${searchParams}`);
}