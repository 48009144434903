import type { Entities } from "@inwink/entities/entities";
import type { IOrderRegistrationBasket, IOrderRegistrationBasketItem } from "@inwink/ticketing/definitions";

export function getInitialBasketForRenewMembership(inwinkRenewOrderId: string, order: Entities.IOrderCommunity) {
    const memberInfos = objectPropertiesToCamelCase(order.orderItems?.[0]?.services?.[0]?.applicationData?.Member);
    const renewProductId = order.orderItems?.[0]?.productId;

    if (!memberInfos || !renewProductId) {
        return null;
    }

    const initialBasket = { items: [], buyer: {}, order: {},
        discountCodes: [], paymentMode: null } as IOrderRegistrationBasket;
    initialBasket.id = inwinkRenewOrderId;
    initialBasket.buyer = {
        ...order.buyer
    };
    initialBasket.items = [
        {
            infos: [{
                ...memberInfos
            }] as any,
            productId: renewProductId,
            quantity: 1
        } as IOrderRegistrationBasketItem
    ];
    return initialBasket;
}

function objectPropertiesToCamelCase(data) {
    if (!data) {
        return null;
    }
    return Object.entries(data).reduce((acc, [key, field]) => {
        const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        acc[camelCaseKey] = field;
        return acc;
    }, {}) as any;
}
