import * as React from 'react';
import { IItemsProperties, getBlocTemplateFor, getItemsProperties } from '@@services/itemshelpers';
import { ProductItem } from './product.item';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { ItemTemplateProvider } from '@@components/templates/itemtemplateprovider';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import type { IOrderRegistrationConfiguration, IOrderRegistrationItem }
    from '@inwink/ticketing/definitions';
import { OrderManager } from '@inwink/ticketing/ordermanager';
import { ContentStyle } from '@@components/contentstyle';
import type { IDynamicBlocBaseProps } from '@@components/dynamicpage/definitions';
import { orderRegistrationProductsStepIsValid } from '@inwink/ticketing/steps/orderregistration.step.products';
import { LoadingState } from '@inwink/loadable/loadingstate';
import * as moment from 'moment';

export interface IProductItemsProps extends IDynamicBlocBaseProps {
    orderRegistrationConfig: IOrderRegistrationConfiguration;
    items: IOrderRegistrationItem[];
    orderManager: OrderManager;
    template: VisualTheme.IBlocContentTemplateBase;
    bloctemplate: VisualTheme.IBlocTemplateBase;
    visualstate: string[];
    templateProperties: any;
    stepProps: any;
}

interface IProductItemsState {
    itemtemplate: VisualTheme.IItemTemplate;
    itemsProperties: IItemsProperties,
}

export class ProductItems extends React.Component<IProductItemsProps, IProductItemsState> {
    constructor(props: IProductItemsProps) {
        super(props);
        const itemtemplate = getBlocTemplateFor(
            props.stepProps?.stepData, props.stepProps?.template);
        const itemsProperties = getItemsProperties(
            props.bloctemplate.id,
            props.visualstate,
            null,
            itemtemplate, "ukn", "ukn");

        this.state = {
            itemtemplate,
            itemsProperties,
        };
    }

    componentDidMount(): void {
        const { orderManager, orderRegistrationConfig } = this.props;
        orderManager.loadItems()
            .then(() => {
                // on skip la step produit uniquement en monoproduit
                if (orderRegistrationConfig.enableMonoProduct && orderRegistrationProductsStepIsValid(orderManager)
                    && orderManager.stepIdx === 0 && !orderManager.lastStepIdx
                ) {
                    // l'étape 1 est celle de selection de produit
                    // et on ne revient pas en arrière on avance sur la step suivante
                    orderManager.changeStep(1);
                } else {
                    orderManager.updateStepValidity(orderRegistrationProductsStepIsValid(orderManager));
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<IProductItemsProps>, prevState: Readonly<IProductItemsState>): void {
        const { orderManager, stepProps } = this.props;
        const newItemTemplate = getBlocTemplateFor(this.props.stepProps?.stepData, this.props.stepProps?.template);
        const newItemsProperties = getItemsProperties(
            this.props.bloctemplate.id,
            this.props.visualstate,
            null,
            newItemTemplate, "ukn", "ukn");
        // On met à jour les templates quand on change d'étape
        if (prevState.itemtemplate !== newItemTemplate && prevState.itemsProperties !== newItemsProperties) {
            this.setState({
                itemtemplate: newItemTemplate,
                itemsProperties: newItemsProperties
            });
        }

        // Permettre de passer un étape de produit sans modifier le panier
        if (orderManager.basket.items.length > 0
            && prevProps.stepProps.stepId !== stepProps.stepId) {
            orderManager.updateStepValidity(true);
        }
    }

    getTemplate(templatename: string) {
        if (this.state.itemtemplate && this.state.itemtemplate[templatename]) {
            return this.state.itemtemplate[templatename];
        }
    }

    getTemplateProperties() {
        return this.state.itemsProperties;
    }

    sortBy(sort, currentLanguageCode) {
        return function (a, b) {
            const { propName, order } = sort || {};
            const valueA = a.initialProduct[propName];
            const valueB = b.initialProduct[propName];

            let type;
            if (moment(valueA, moment.ISO_8601, true).isValid() && moment(valueB, moment.ISO_8601, true).isValid()) {
                type = 'date';
            } else if (valueA && valueB && typeof valueA[currentLanguageCode] === 'string'
                && typeof valueB[currentLanguageCode] === 'string') {
                type = 'string';
            } else {
                type = 'number';
            }

            switch (type) {
                case 'date': {
                    const dateA = moment(valueA);
                    const dateB = moment(valueB);
                    if (dateA.isBefore(dateB)) {
                        return order === 'asc' ? -1 : 1;
                    } else if (dateA.isAfter(dateB)) {
                        return order === 'asc' ? 1 : -1;
                    } else {
                        return 0;
                    }
                }
                case 'string': {
                    const comparison = valueA[currentLanguageCode]
                        .localeCompare(valueB[currentLanguageCode], currentLanguageCode, { sensitivity: 'variant' });
                    return order === 'asc' ? comparison : -comparison;
                }
                case 'number': {
                    const numA = parseFloat(valueA);
                    const numB = parseFloat(valueB);
                    const comparison = numA - numB;
                    return order === 'asc' ? comparison : -comparison;
                }
                default:
                    return 0;
            }
        };
    }

    renderProductItems() {
        let content = null;
        const { stepProps, orderManager, i18n } = this.props;
        let items = orderManager.items ?? [];

        // Manual selection
        const selectedProductIds = stepProps.productsList ?? [];
        if (selectedProductIds.length > 0) {
            items = items.filter(item => selectedProductIds.includes(item.id));
        }

        // Filters
        if (stepProps?.itemsFilter != null) {
            items = items.filter((item) => stepProps.itemsFilter(item.initialProduct));
        }

        if (stepProps?.excludedProductTypes?.length > 0) {
            items = items.filter(item => !stepProps.excludedProductTypes.includes(item.category));
        }

        if (items?.length > 0) {
            // Sort
            if (stepProps?.sort !== null) {
                items = items.sort(this.sortBy(stepProps.sort, i18n.currentLanguageCode));
            }

            content = items.map((product) => {
                return <ItemTemplateProvider provider={this} key={product.id}>
                    <ProductItem
                        {...this.props}
                        className="products-items"
                        product={product}
                        itemtemplate={this.state.itemtemplate}
                        properties={this.state.itemsProperties}
                    />
                </ItemTemplateProvider>;
            });
        } else {
            content = <AppTextLabel
                className="noproducts"
                i18n="orderregistration.products.noproducts"
                component='p' />;
        }

        return content;
    }

    render() {
        const { itemsLayout, itemsAlign } = this.state.itemsProperties;
        const { orderManager, orderRegistrationConfig, stepProps } = this.props;
        const hideBasket = orderRegistrationConfig?.enableMonoProduct && !stepProps.isAdditionalProduct;

        return <div className={`step-content product ${hideBasket ? "monoproduct" : ""}`}>
            <AppTextLabel className="step-title products-title" i18n="orderregistration.products.title" component='h3' />
            <LoadingState isLoading={orderManager?.loading && orderRegistrationConfig?.enableMonoProduct}>
                <div className={"order-products-items inwink-items layout-" + itemsLayout + " align-" + itemsAlign}>
                    <ContentStyle
                        css={(this.state.itemtemplate as any)?.customCSS}
                        theme={this.props.theme}
                        contentid={this.props.id}
                    />
                    {this.renderProductItems()}
                </div>
            </LoadingState>
        </div>;
    }
}
